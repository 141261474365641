import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, model } from '@angular/core';

@Component({
    selector: 'app-password-complexity',
    imports: [
        CommonModule,
    ],
    templateUrl: './password-complexity.component.html',
    styleUrl: './password-complexity.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordComplexityComponent { 

  password = model.required<string>();

  get hasNumber(): boolean {
    return /\d/.test(this.password());
  }

  get hasUpperCase(): boolean {
    return /[A-Z]/.test(this.password());
  }

  get hasLowerCase(): boolean {
    return /[a-z]/.test(this.password());
  }

  get hasSpecialCharacter(): boolean {
    return /[!@#$%^&*(),.?":{}|<>]/.test(this.password());
  }

  get hasMinLength(): boolean {
    return this.password().length >= 8;
  }

}
